import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterModule, Routes } from '@angular/router';
import { take } from 'rxjs/operators';
import {
  AuthGuard,
  DhhAuthGuard,
  InteractiveAccessAuthGuard,
  NonInteractiveAccessAuthGuard,
  NonVrAuthGuard,
  PortalAuthGuard,
  UberAdminGuard,
  VrAuthGuard,
} from './auth/auth.guard';
import { NavComponent } from './nav/nav.component';
import { HealthCheckComponent } from './shared/components/health-check/health-check.component';
import { LoggedOutSsoComponent } from './shared/components/logged-out-sso/logged-out-sso.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { PdfDraftViewerComponent } from './shared/components/pdf-draft-viewer/pdf-draft-viewer.component';
import { PdfViewerComponent } from './shared/components/pdf-viewer/pdf-viewer.component';
import { SamlUnauthorizedScreenComponent } from './shared/components/saml-unauthorized-screen/saml-unauthorized-screen.component';
import { SandboxUnauthorizedScreenComponent } from './shared/components/sandbox-unauthorized/sandbox-unauthorized-screen.component';
import { UnauthorizedScreenComponent } from './shared/components/unauthorized-screen/unauthorized-screen.component';
import { FamilyGuard } from './shared/guards/family.guard';
import { CaseSummary } from './shared/models/case';
import { CaseService } from './shared/services/case/case.service';
import { FamilyMemberValidationDashboardComponent } from './family-member-validation-dashboard/family-member-validation-dashboard.component';

@Injectable({
  providedIn: 'root',
})
class CaseResolver implements Resolve<CaseSummary> {
  constructor(private caseService: CaseService) {}

  resolve(next: ActivatedRouteSnapshot) {
    const caseId = next.paramMap.get('caseId');
    return new Promise<CaseSummary>((res) => this.caseService.getCaseSummary(caseId).pipe(take(1)).subscribe(res));
  }
}

const routes: Routes = [
  {
    path: 'early-access-referral',
    loadChildren: () =>
      import('./child-find/early-access-referral/early-access-referral.module').then((mod) => mod.EarlyAccessReferralModule),
  },
  {
    path: 'state-complaint',
    loadChildren: () => import('./state-complaint-public/state-complaint-public.module').then((mod) => mod.StateComplaintPublicModule),
  },
  {
    path: 'due-process',
    loadChildren: () => import('./due-process-public/due-process-public.module').then((mod) => mod.DueProcessPublicModule),
  },
  {
    path: '',
    component: NavComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./universal-dashboard/universal-dashboard.module').then((mod) => mod.UniversalDashboardModule),
        canActivate: [FamilyGuard],
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((mod) => mod.AdminModule),
        canActivate: [NonVrAuthGuard, NonInteractiveAccessAuthGuard],
      },
      {
        path: 'child-find',
        loadChildren: () => import('./child-find/child-find.module').then((mod) => mod.ChildFindModule),
      },
      {
        path: 'cases/:caseId/evaluation',
        loadChildren: () => import('./evaluation/evaluation.module').then((mod) => mod.EvaluationModule),
      },
      {
        path: 'cases/:caseId/reevaluation',
        loadChildren: () => import('./reevaluation/reevaluation.module').then((mod) => mod.ReevaluationModule),
      },
      {
        path: 'learner-management',
        loadChildren: () => import('./learner-management/learner-management.module').then((mod) => mod.LearnerManagementModule),
      },
      {
        path: 'cases/:caseId/iep',
        loadChildren: () => import('./iep/iep.module').then((mod) => mod.IepModule),
      },
      {
        path: 'cases/:caseId/ifsp',
        loadChildren: () => import('./ifsp/ifsp.module').then((mod) => mod.IfspModule),
      },
      {
        path: 'cases/:caseId/behavior-discipline',
        loadChildren: () => import('./behavior-discipline/behavior-discipline.module').then((mod) => mod.BehaviorDisciplineModule),
      },
      {
        path: 'cases/:caseId/pwns',
        loadChildren: () => import('./pwn/pwn.module').then((mod) => mod.PwnModule),
        resolve: {
          case: CaseResolver,
        },
      },
      {
        path: 'sif',
        loadChildren: () => import('./sif/sif.module').then((mod) => mod.SifModule),
        canActivate: [NonVrAuthGuard, NonInteractiveAccessAuthGuard],
      },
      {
        path: 'tags',
        loadChildren: () => import('./tags/tags.module').then((mod) => mod.TagsModule),
      },
      {
        path: 'sticky-notes',
        loadChildren: () => import('./sticky-notes/sticky-notes.module').then((mod) => mod.StickyNotesModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then((mod) => mod.UsersModule),
        canActivate: [NonVrAuthGuard, NonInteractiveAccessAuthGuard],
      },
      {
        path: 'consent-signature',
        loadChildren: () => import('./consent-signature/consent-signature.module').then((mod) => mod.ConsentSignatureModule),
      },
      {
        path: 'monitoring-progress',
        loadChildren: () => import('./monitoring-progress/monitoring-progress.module').then((mod) => mod.MonitoringProgressModule),
      },
      {
        path: 'test',
        loadChildren: () => import('./test/test.module').then((mod) => mod.TestModule),
        canActivate: [UberAdminGuard, NonVrAuthGuard, NonInteractiveAccessAuthGuard],
      },
      {
        path: 'medicaid',
        loadChildren: () => import('./medicaid/medicaid.module').then((mod) => mod.MedicaidModule),
      },
      {
        path: 'canned-report',
        loadChildren: () => import('./canned-report/canned-report.module').then((mod) => mod.CannedReportModule),
      },
      {
        path: 'portal',
        loadChildren: () => import('./portal/portal.module').then((mod) => mod.PortalModule),
        canActivate: [PortalAuthGuard, NonVrAuthGuard, NonInteractiveAccessAuthGuard],
      },
      {
        path: 'my-tools',
        loadChildren: () => import('./my-tools/my-tools.module').then((mod) => mod.MyToolsModule),
        canActivate: [NonVrAuthGuard],
      },
      {
        path: 'surveys',
        loadChildren: () => import('./surveys/surveys.module').then((mod) => mod.SurveyModule),
        canActivate: [NonVrAuthGuard, NonInteractiveAccessAuthGuard],
      },
      {
        path: 'ivrs',
        loadChildren: () => import('./ivrs/ivrs.module').then((mod) => mod.IvrsModule),
        canActivate: [VrAuthGuard],
      },
      {
        path: 'dhh',
        loadChildren: () => import('./dhh/dhh.module').then((mod) => mod.DhhModule),
        canActivate: [DhhAuthGuard],
      },
      {
        path: 'interactive-access',
        loadChildren: () => import('./interactive-access/interactive-access.module').then((mod) => mod.InteractiveAccessModule),
        canActivate: [InteractiveAccessAuthGuard],
      },
      {
        path: 'family-member-validation-dashboard',
        component: FamilyMemberValidationDashboardComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((mod) => mod.AuthModule),
  },
  {
    path: 'signedforms',
    loadChildren: () => import('./signed-forms/signed-forms.module').then((mod) => mod.SignedFormsModule),
  },
  {
    path: 'documents/:learnerId/:documentId',
    component: PdfViewerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'documents/generic/docid/:documentId',
    component: PdfViewerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'documents/draft',
    component: PdfDraftViewerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'logged-out-sso',
    component: LoggedOutSsoComponent,
  },
  {
    path: 'sandbox-unauthorized',
    component: SandboxUnauthorizedScreenComponent,
  },
  {
    path: 'saml-unauthorized',
    component: SamlUnauthorizedScreenComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedScreenComponent,
  },
  {
    path: 'health-check',
    component: HealthCheckComponent,
  },
  {
    path: 'survey-tool',
    loadChildren: () => import('./survey-tool/survey-tool.module').then((mod) => mod.SurveyToolModule),
  },
  {
    path: '*',
    component: NotFoundComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
