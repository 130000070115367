import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { AlertConfig } from '../shared/components/page-alert/page-alert.component';
import { SystemMessageService } from '../shared/services/system-message.service';
import { Router } from '@angular/router';
import { StateComplaintService } from '../my-tools/dispute-resolution/state-complaint/shared/services/state-complaint.service';

@Component({
  selector: 'app-universal-dashboard',
  templateUrl: './universal-dashboard.component.html',
  styleUrls: ['./universal-dashboard.component.scss'],
})
export class UniversalDashboardComponent implements OnInit {
  todoList: any;
  isFamily = false;
  isEligibleFamily = false;
  isAea = false;

  siteWideAlert: AlertConfig;

  constructor(
    private readonly authService: AuthService,
    private readonly stateComplaintService: StateComplaintService,
    private readonly systemMessageService: SystemMessageService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    if (this.authService.isVrUser) {
      this.router.navigate(['/', 'ivrs']);
      return;
    }
    if (this.authService.isInteractiveAccessUser) {
      this.authService.navigateToDisputeResolutionDashboard();
      return;
    }
    if (this.authService.isPortalUser) {
      this.router.navigate(['portal']);
      return;
    }
    this.isFamily = this.authService.isFamily;
    this.isEligibleFamily = this.authService.isEligibleFamily;
    this.isAea = !this.isFamily && !this.isEligibleFamily;
    this.systemMessageService.getActive().subscribe((msg) => {
      if (msg?.hasActive) {
        this.siteWideAlert = {
          status: 'info',
          title: msg.title,
          message: msg.message,
        };
      }
    });
  }
}
